<template>
  <div
    id="ProductPriceScales"
    :class="{'is-loading': isLoading}"
  >
    <div class="loading-container">
      <AppLoadingIndicator />
    </div>
    <div
      v-show="rows && rows.length > 0"
      class="prod-d-pricescale__wrapper custom-scrollbar"
      :class="{'dynamicHeight': dynamicHeight}"
    >
      <div class="prod-d-pricescale__row">
        <div
          class="prod-d-pricescale__col header qty"
          v-tv:product="'qty'"
        />
        <div
          class="prod-d-pricescale__col header price-per-hundred"
          v-tv:product="'price-per-100'"
        />
        <div
          class="prod-d-pricescale__col header rec-per-hundred"
          v-if="showRecommendedPrice"
          v-tv:product="'rec-per-100'"
        />
      </div>
      <div
        class="prod-d-pricescale__row"
        v-for="(row, index) in rows"
        :key="index"
        :class="{'not-applicable': !row.isApplicable}"
      >
        <div class="prod-d-pricescale__col qty">
          {{ row.quantity }}
        </div>
        <div class="prod-d-pricescale__col price-per-hundred">
          <Price :price="row.price" />
        </div>
        <div
          class="prod-d-pricescale__col rec-per-hundred"
          v-if="showRecommendedPrice"
        >
          <Price :price="row.recommendedPrice" />
        </div>
      </div>
    </div>
    <div
      v-show="!rows || rows.length === 0"
      class="no-price-scales"
      v-tv:product="'noPriceScalesFound'"
    />
  </div>
</template>

<script>
import Price from '~/components/PriceF'
import AppLoadingIndicator from '~/components/loading-indicators/app-loading-indicator.vue'

export default {
  name: 'ProductPriceScales',
  components: {Price, AppLoadingIndicator},
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    showRecommendedPrice: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    rows: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    dynamicHeight: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
[id='ProductPriceScales'] {
  ::-webkit-scrollbar-thumb {
    min-height: 3em;
  }

  .prod-d-pricescale {
    &__wrapper {
      overflow-y: scroll;
      scrollbar-width: thin;
      scrollbar-color: $color__primary $color__page--background;

      &.dynamicHeight {
        overflow-y: hidden;
        z-index: 299;
      }
    }

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &.not-applicable {
        opacity: .5;
      }
    }

    &__col {
      display: flex;
      flex-direction: column;
      font-weight: lighter;
      line-height: 1.25rem;
      margin-bottom: .313rem;

      &.header {
        font-weight: $base-text-font-weight-semi-bold;
      }

      &.qty {
        width: 20%;
      }

      &.price-per-hundred,
      &.rec-per-hundred {
        width: 40%;
        text-align: right;
      }

      &:last-child {
        margin-right: $default-margin-padding-width;
      }
    }
  }

  .loading-container {
    display: none;
  }

  .no-price-scales {
    font-size: $base-text-font-size;
    font-weight: $base-text-font-weight-light;
  }

  &.is-loading {
    .prod-d-pricescale__wrapper,
    .no-price-scales {
      display: none;
    }

    .loading-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
