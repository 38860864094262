<template>
  <div class="stock-schedule">
    <div
      v-show="stockScheduleRows && stockScheduleRows.length > 0"
      class="stock-schedule__wrapper"
    >
      <div class="stock-schedule__row header">
        <div class="col-l">
          {{ $tv('entryDate', 'product') }}
        </div>
        <div class="col-r">
          {{ $tv('quantity', 'product') }}
        </div>
      </div>
      <div
        class="stock-schedule__row values"
        v-for="(row, index) in stockScheduleRows"
        :key="index"
      >
        <div class="col-l">
          <FormatDate :date="getDate(row.date)" />
        </div>
        <div class="col-r">
          <QuantityDisplay
            :quantity="row.quantity"
            :unit="row.unit"
          />
        </div>
      </div>
    </div>
    <div
      v-show="!(stockScheduleRows && stockScheduleRows.length > 0)"
      v-tv:product="'noAvailability'"
      class="stock-schedule__not-available"
    />
  </div>
</template>

<script>
import FormatDate from '~/components/FormatDate'
import QuantityDisplay from '~/components/QuantityDisplay'

export default {
  name: 'ProductStock',
  components: {FormatDate, QuantityDisplay},
  inheritAttrs: false,
  props: {
    stockScheduleRows: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  methods: {
    getDate(date) {
      return new Date(date)
    }
  }
}
</script>

<style lang="scss">
.stock-schedule {
  &__wrapper {
    margin-top: $default-margin-padding-width;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__not-available {
    margin-top: .8rem;
  }

  &__row {
    font-size: $base-text-font-size;
    font-weight: $base-text-font-weight-light;
    display: flex;
    justify-content: space-between;

    > div {
      padding: .6rem $default-margin-padding-width;
    }

    &.header {
      background-color: $color__primary;
      color: $color__white;
    }

    &.values {
      background-color: $color__white;
      margin-top: .06rem;

      &:nth-child(odd) {
        background-color: $color__content--background__secondary--opacity;
      }
    }

    .col-l {
      display: flex;
      justify-content: flex-start;
    }

    .col-r {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
