<template>
  <client-only>
    <div
      class="product-datepicker enext-input--status pure-form"
      :class="[
        dateValidation.type === validationTypes.OK || dateValidation.type === validationTypes.WARNING ? 'ok' : '',
        dateValidation.type === validationTypes.ERROR ? 'error' : '',
        disabled ? 'disabled' : '',
        $attrs?.class ? $attrs.class : ''
      ]"
    >
      <div @click="hideShowDatePicker">
        <DatePicker
          @change="handleDateChange"
          @blur="handleDateBlur"
          @focus="handleDateFocus"
          :value="chosenDate"
          :default-show="false"
          :disabled="disabled"
          :show="showDatePicker && !disabled && !showDateTooltip"
          class="hide-calendar-select input-box"
          v-bind="customAttrs"
          :min="minAvailabilityDate"
          :max="maxAvailabilityDate"
        />
      </div>
      <div
        class="status-indicator"
      >
        <div
          class="status-indicator__symbol-wrapper"
          @click="emitVisibility(null)"
        >
          <NavigationCheck v-show="dateValidation.type === validationTypes.OK" />
          <Exclamation
            v-show="(
              dateValidation.type === validationTypes.WARNING ||
              dateValidation.type === validationTypes.ERROR
            )"
          />
        </div>
        <tool-tip
          :show-tool-tip="!disabled && showDateTooltip"
          tool-tip-position="bottom"
          arrow-position-vertically="top"
          arrow-position-horizontally="right"
          :allow-close="true"
          @close="emitVisibility(false)"
        >
          <div class="tooltip-message">
            <div class="tooltip-message__inner-container">
              <span
                v-if="dateValidation.message === validationMessages.NOT_LOADED"
                v-tv:product="'availabilitiesCouldNotBeLoaded'"
              />
              <span
                v-else-if="dateValidation.type === validationTypes.ERROR && dateValidation.message === validationMessages.DATEPASTINVALID"
                v-tv:product="'pastDate'"
              />
              <span
                v-else-if="dateValidation.type === validationTypes.ERROR && dateValidation.message === validationMessages.DATEFUTUREINVALID"
                v-tv:product="'futureDate'"
              />
              <span
                v-else-if="dateValidation.type === validationTypes.WARNING"
                v-tv:product="'notAvailable'"
              />
              <span
                v-else
                v-tv:product="'isAvailable'"
              />
            </div>
            <ProductStock
              v-if="dateValidation.message !== validationMessages.NOT_LOADED && !disabled"
              :stock-schedule-rows="stockRows"
            />
          </div>
        </tool-tip>
      </div>
    </div>
  </client-only>
</template>

<script>
import DatePicker from '~/components/input-components/DatePicker'
import ToolTip from '~/components/ToolTip.vue'
import {getNextDate, getMaxDate} from '~/assets/js/utils'
import ProductStock from '~/components/product-detail/buy-box/ProductStock'
import {ITEMS} from '~/assets/js/constants'
import NavigationCheck from '~/assets/icons/navigation_check.svg?component'
import Exclamation from '~/assets/icons/exclamation.svg?component'

export default {
  name: 'ProductDatepicker',
  components: {
    ProductStock,
    DatePicker,
    ToolTip,
    NavigationCheck,
    Exclamation
  },
  inheritAttrs: false,
  props: {
    currentDate: {
      type: Date,
      default() {
        return getNextDate()
      }
    },
    dateValidation: {
      type: Object,
      default() {
        return {}
      }
    },
    showDateTooltip: {
      type: Boolean,
      default() {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    },
    stockScheduleRows: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    showOnlyFirstEntry: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      chosenDate: getNextDate(),
      minAvailabilityDate: getNextDate(),
      maxAvailabilityDate: getMaxDate(),
      showDatePicker: false,
      validationTypes: ITEMS.VALIDATION_TYPES,
      validationMessages: ITEMS.AVAILABILITY_DATE_MESSAGES,
      blurTimeout: undefined
    }
  },
  methods: {
    handleDateChange(date) {
      this.hideShowDatePicker()
      this.$emit('dateChanged', date.value)
    },
    handleDateBlur() {
      this.blurTimeout = setTimeout(() => {
        this.$emit('dateBlur', this.chosenDate)
        this.showDatePicker = false
      }, 50)
    },
    handleDateFocus() {
      if (this.blurTimeout) {
        clearTimeout(this.blurTimeout)
      }
    },
    emitVisibility(visible) {
      this.showDatePicker = false
      this.$emit('tooltipVisibility', visible === null ? !this.showDateTooltip : visible)
    },
    hideShowDatePicker() {
      if (this.showDateTooltip && !this.showDatePicker) {
        this.$emit('tooltipVisibility', false)
      }
      this.showDatePicker = !this.showDatePicker
    }
  },
  computed: {
    stockRows() {
      return this.showOnlyFirstEntry && this.stockScheduleRows.length > 0 ? this.stockScheduleRows.slice(0) : this.stockScheduleRows
    },
    customAttrs() {
      let attrs = {...this.$attrs, class: ''}
      return attrs
    }
  },
  watch: {
    currentDate: {
      immediate: true,
      handler(date) {
        if (date instanceof Date && !isNaN(date)) {
          this.chosenDate = date
        } else {
          this.chosenDate = getNextDate()
        }
      }
    }
  }
}
</script>

<style lang="scss">
.product-datepicker {
  .k-datepicker {
    width: 100%;
  }

  .tooltip-message {
    font-size: map-get($base-text-label-small, font-size );
    line-height: map-get($base-text-label-small, line-height );
    font-weight:  map-get($base-text-label-small, font-weight);

    &__inner-container {
      padding-right: 1.5rem;
    }
  }

  &.disabled {
    .status-indicator {
      background-color: $color__datepicker--disabled;
    }

    .k-input {
      border-color: $color--input__border;
    }
  }
}
</style>
