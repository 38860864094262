<template>
  <client-only>
    <div
      class="product-quantity enext-input enext-input--status pure-form"
      :class="[
        quantityValidation.type === quantityWarningTypes.ERROR ? 'error' : '',
        quantityValidation.type === quantityWarningTypes.INFO ? 'info' : '',
        $attrs?.class ? $attrs.class : ''
      ]"
    >
      <div class="product-quantity__input">
        <NumericInput
          v-model="quantity"
          class="input-box"
          @input="handleQuantityChange"
          @focusOut="emitChange"
          :disabled="disabled"
          v-bind="customAttrs"
          :min="minQuantity"
          :max="maxQuantity"
          :disable-live-update="true"
          :ref="focusRefId"
        />
      </div>
      <div
        class="status-indicator"
      >
        <div
          class="status-indicator__symbol-wrapper"
          @click="emitVisibility(null)"
        >
          <Exclamation />
        </div>
        <tool-tip
          v-if="quantityValidation"
          :show-tool-tip="showQuantityTooltip"
          tool-tip-position="top"
          arrow-position-vertically="bottom"
          arrow-position-horizontally="right"
          :allow-close="true"
          @close="emitVisibility(false)"
        >
          <div class="tooltip-message">
            <div
              class="error"
              v-for="(warning, index) in quantityValidation.quantityWarnings"
              :key="index"
            >
              <span
                v-html="$tv(warning, 'product.warning')"
              />
            </div>
            <div
              v-if="quantityValidation.hasAdjustments"
              class="info"
              :class="{loading: adjustments.loading}"
            >
              <div class="loading-container">
                <WaitingIndicator />
              </div>
              <div
                class="tooltip-message__inner-container"
                v-if="adjustments && adjustments.adjustments && adjustments.adjustments.suggestedQuantity <= adjustments.adjustments.requestedQuantity"
                v-html="$tv('netValueAdjusted', 'product')"
              />
              <div
                class="tooltip-message__inner-container"
                v-else-if="adjustments && adjustments.adjustments && adjustments.adjustments.suggestedQuantity > adjustments.adjustments.requestedQuantity"
              >
                <span
                  v-html="$tv('minimumOrder', 'product')"
                />
                <div
                  v-if="adjustments.adjustments"
                  class="adjust-quantity"
                >
                  <span>{{ adjustments.adjustments.suggestedQuantityText }}</span>
                  <a
                    href="javascript:void(0)"
                    @click="adjustQuantity"
                    v-tv:product="'adjustQuantity'"
                  />
                </div>
              </div>
            </div>
          </div>
        </tool-tip>
      </div>
    </div>
  </client-only>
</template>

<script>
import ToolTip from '~/components/ToolTip.vue'
import {ITEMS, MIN_MAX_VALUES} from '~/assets/js/constants'
import NumericInput from '~/components/input-components/NumericInput'
import WaitingIndicator from '~/components/loading-indicators/waiting-indicator.vue'
import Exclamation from '~/assets/icons/exclamation.svg?component'

export default {
  name: 'ProductQuantity',
  components: {
    NumericInput,
    ToolTip,
    WaitingIndicator,
    Exclamation
  },
  inheritAttrs: false,
  props: {
    quantityValidation: {
      type: Object,
      default() {
        return {}
      }
    },
    adjustments: {
      type: Object,
      default() {
        return {}
      }
    },
    currentQuantity: {
      type: Number,
      default() {
        return 1
      }
    },
    showQuantityTooltip: {
      type: Boolean,
      default() {
        return false
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    focusRefId: {
      type: String,
      required: false,
      default() {
        return 'productQuantity'
      }
    }
  },
  data() {
    return {
      oldQuantity: this.currentQuantity,
      quantity: this.currentQuantity,
      minQuantity: MIN_MAX_VALUES.Quantity.Min,
      maxQuantity: MIN_MAX_VALUES.Quantity.Max,
      quantityWarningTypes: ITEMS.VALIDATION_TYPES
    }
  },
  computed: {
    customAttrs() {
      let attrs = {...this.$attrs, class: ''}
      return attrs
    }
  },
  methods: {
    adjustQuantity() {
      if (this.adjustments?.adjustments?.suggestedQuantity) {
        this.quantity = this.adjustments.adjustments.suggestedQuantity
        this.emitChange()
        this.emitVisibility(false)
      }
    },
    handleQuantityChange(e) {
      this.$emit('startEditing', e)
    },
    emitVisibility(visible) {
      // eslint-disable-next-line eqeqeq
      if (visible == null) {
        this.$emit('tooltipVisibility', !this.showQuantityTooltip)
      } else {
        this.$emit('tooltipVisibility', visible)
      }
    },
    emitChange() {
      if (this.quantity &&
          this.adjustments?.adjustments?.suggestedQuantity &&
          this.quantity >= this.adjustments.adjustments.suggestedQuantity) {
        this.emitVisibility(false)
      }
      this.$emit('quantityChange', this.quantity)
    }
  },
  watch: {
    currentQuantity: {
      immediate: true,
      handler() {
        this.quantity = this.currentQuantity
        this.oldQuantity = this.quantity
      }
    },
    focusRefId: {
      immediate: true,
      handler() {
        if (!this.focusRefId) {
          return
        }
        this.$nextTick(() => {
          setTimeout(() => {
            const ref = this.$refs[this.focusRefId]
            if (ref) {
              const inputRef = ref.$refs[ref.uuid + '_numericinput']
              if (inputRef) {
                inputRef.selectionStart = 100
                inputRef.selectionEnd = 100
                inputRef.focus()
              }
            }
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
.product-quantity {
  &.error,
  &.info {
    .apply-changes-button {
      right: 2.5625rem;
    }
  }

  .tooltip-message {
    font-size: $base-text-font-size;
    line-height: $base-text-line-height;
    font-weight: $base-text-font-weight-light;

    > div:not(:first-child) {
      margin-top: $default-margin-padding-width;
      padding-top: $default-margin-padding-width;
      border-top: .0625rem solid $color__primary;
    }

    &__inner-container {
      padding-right: 1.5rem;
    }

    .loading-container {
      display: none;
    }

    .loading {
      > div {
        display: none;
      }

      .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .info {
      color: $color__primary;
    }

    .error {
      color: $color__text--error;
    }

    .adjust-quantity {
      margin-top: $default-margin-padding-width-small;
      font-weight: $base-text-font-weight-bold;

      > a {
        margin-left: $default-margin-padding-width;
      }
    }
  }
}
</style>
